import { useParams } from "react-router-dom";
import GoBackButton from "../components/GoBackButton.js";
import "../styles/BieresPermaDetailsPage.css";


import EbcWidget from "../components/EbcWidget.js";
import IbuWidget from "../components/IbuWidget.js";
import sendGA4Event from "../components/GA4Function.js";

// import BV_can from "../assets/bieres/BV_can.jpg";
// import ET_can from "../assets/bieres/ET_can.jpg";
// import PAP_can from "../assets/bieres/PAP_can.jpg";
// import OH_can from "../assets/bieres/OH_can.jpg";
import BV_verre from "../assets/bieres/BV_verre.jpg";
import ET_verre from "../assets/bieres/ET_verre.jpg";
import PAP_verre from "../assets/bieres/PAP_verre.jpg";
import OH_verre from "../assets/bieres/OH_verre.jpg";


import MOCK_P from "../assets/bieres/mockup_P.png";
import MOCK_EV from "../assets/bieres/mockup_EV.png";
import MOCK_OLL from "../assets/bieres/mockup_OLL.png";
import MOCK_BV from "../assets/bieres/mockup_BV.png";
import MOCK_F from "../assets/bieres/mockup_F.png";
import MOCK_DD from "../assets/bieres/mockup_DD.png";
import MOCK_SMASH from "../assets/bieres/mockup_smash_outstock.png";
import MOCK_ALORS from "../assets/bieres/mockup_alors_outstock.png";
import MOCK_SAPER from "../assets/bieres/mockup_saper.png";
import MOCK_SWEET from "../assets/bieres/mockup_sweet.png";
import MOCK_EMMENEZ from "../assets/bieres/mockup_emmenez.png";
import MOCK_LOVE from "../assets/bieres/mockup_love.png";

function BieresPermaDetailsPage() {
    const {beerId} = useParams();
    
    const biere_list = [
        {
            id : "smash",
            nom : "SMASH... et Bim ! (Épuisée)",
            style : "S.M.A.S.H",
            photo1 : MOCK_SMASH,
            photo2 : MOCK_SMASH,
            EBC : 9.5,
            IBU : 27,
            houblons : ["Cascade BIO"],
            malts : ["Pale Ale"],
            levure : ["US-05"],
            alcool : 5,
            amertume : "Amertume modérée",
            desc_courte : "La SMASH... et Bim ! revient en 2025 sous un nouveau jour !",
            desc_longue :"La simplicité d’une SMASH: un seul malt , le PALE ALE avec son goût doux et légèrement céréalier; un seul Houblon,\
                         le CASCADE aux arômes floraux et d’agrumes, particulièrement bien présents  sur la récolte 2023 chez Poppin’s hops,\
                          notre partenaire à Boissière de Montaigu." 
        },
        {
            id : "caalors",
            nom : "Ça alors!  (Épuisée)",
            style : "HOPPY LAGER",
            photo1 : MOCK_ALORS,
            photo2 : MOCK_ALORS,
            EBC : 6.5,
            IBU : 18,
            houblons : ["Talus","Idaho 7"],
            malts : ["Pilsen","Froment"],
            levure : ["W34-70"],
            alcool : 4.9,
            amertume : "Amertume modérée",
            desc_courte : "Une HOPPY LAGER aromatique et rafraichissante. La 'Ça alors!' revient en 2025 !",
            desc_longue :"Cette LAGER, on l’a voulu aromatique et rafraichissante. On lui a attribué le Houblon IDAHO 7 \
                        qui lui donne des touches juteuses de fruits tropicaux et d’agrumes ainsi qu’une pointe épicée. \
                        Et on lui a rajouté le TALUS aux notes fruitées de pamplemousse et citron. " 
        },
        {
            id : "saperlipopette",
            nom : "Saperlipopette !",
            style : "WHEAT ALE",
            photo1 : MOCK_SAPER,
            photo2 : MOCK_SAPER,
            EBC : 37,
            IBU : 6,
            houblons : ["Hallertau Mittelfrüh","Mistral"],
            malts : ["Pilsen","Carapils","Vienna","malts Cara"],
            levure : ["S-04"],
            alcool : 4.0,
            amertume : "Faible amertume",
            desc_courte : "Au soleil, sous la pluie, à midi ou à minuit, on peut tout accompagner avec une bière de blé !",
            desc_longue :"Cette bière de blé vous surprendra par sa couleur et son goût. Légèrement acidulée grâce au cassis \
                        , et relevée d’une pointe de poivre de Timut aux arômes de pamplemousse , elle est très légère en alcool \
                        , et elle vous accompagnera pour des moments de fraîcheur intenses.. " 
        },
        
        // {
        //     id : "bonv",
        //     nom : "Bon V🛫!",
        //     style : "IPA Ambrée",
        //     photo1 : MOCK_BV,
        //     photo2 : BV_verre,
        //     EBC : 32,
        //     IBU : 36,
        //     houblons : ["Magnum", "Chinook", "Simcoe"],
        //     malts : ["Pale Ale", "Biscuit Caraaroma"],
        //     levure : ["Verdant IPA"],
        //     alcool : 5.8,
        //     amertume : "Amertume modérée",
        //     desc_courte : "Une IPA ambrée à l’amertume modérée, sur une belle base maltée à la couleur chaleureuse.",
        //     desc_longue :"Cette IPA est la combinaison d'une base maltée torrefiée à la fois généreuse et savoureuse aux \
        //                 notes caramel et biscuit, et de houblons apportant une amertume franche. Le CHINOOK apporte des arômes épicés \
        //                 et des notes de pins sur une touche pamplemousse. Le SIMCOE développe des saveurs d'agrumes et florales pétillantes. De quoi vous étonner! " 
        // },
        {
            id : "ohlala",
            nom : "Oh là là!",
            style : "NEIPA",
            photo1 : MOCK_OLL,
            photo2 : OH_verre,
            EBC : 13,
            IBU : 17,
            houblons : ["Citra", "Mosaïc, Sabro"],
            malts : ["Pale Ale", "froment", "Biscuit"],
            levure : ["New England"],
            alcool : 6,
            amertume : "Amertume",
            desc_courte : "Une NEIPA rafraîchissante, savoureuse, aux parfums d'agrumes et de fruits exotiques.",
            desc_longue : "Cette NEIPA à la robe jaune claire, c’est une combinaison de houblons qu’on adore chez « Sans Façon »: \
                            Le Mosaïc  pour ses arômes de fruits à noyau et de pin, le Citra pour ses saveurs d’agrumes explosives et \
                            le Sabro pour ses saveurs complexes de fruits tropicaux, d’agrumes ainsi que sa petite touche coco. Bien sûr \
                            on n’oublie pas d’apporter du corps avec une base maltée alliant orge et froment." //"L’amertume douce et la base maltée bien marquée permettent à la “Bon Voyage!” de s’accorder sur des plas chaleureux mais aussi sur des fromages et de la charcuterie. " 
        },
        {
            id : "papounet",
            nom : "Papounet",
            style : "SAISON au poivre",
            photo1 : MOCK_P,
            photo2 : PAP_verre,
            EBC : 16,
            IBU : 21,
            houblons : ["Elixir", "Mistral", "Barbe rouge"],
            malts : ["Pale Ale", "froment", "Rye", "Cararye"],
            levure : ["Belle Saison"],
            alcool : 5.8,
            amertume : "Amertume équilibrée",
            desc_courte : "Une SAISON aux houblons Français,relevée du parfum subtil du poivre de Kampot.",
            desc_longue :"Le style SAISON  est un style qu’on adore chez « Sans Façon ». Il nous vient de Belgique \
                        avec des saveurs de céréales, des notes fruitées et épicées. On lui donne une belle touche française \
                        avec trois houblons de chez nous. L'Elixir  apporte des notes de pêche et de mirabelle, le Mistral des notes \
                        de fruits blancs et des touches florales rafraîchissantes ,le Barbe Rouge  des notes de fruits rouges. \
                        Et pour apporter du peps, on associe quelques poivres sélectionnés pour leurs saveurs et leur fraîcheur." 
        },
        {
            id : "sweetas",
            nom : "Sweet As !",
            style : "New Zealand IPA",
            photo1 : MOCK_SWEET,
            photo2 : MOCK_SWEET,
            EBC : 8,
            IBU : 37,
            houblons : ["Nelson Sauvin", "Nectaron"],
            malts : ["Pale Ale", "Pilsen", "Carapils", "Vienna"],
            levure : ["High Voltage"],
            alcool : 4.6,
            amertume : "Amertume modérée",
            desc_courte : "Une New Zealand IPA légère pour encore plus de plaisir.",
            desc_longue :"Cette NEW Zealand IPA a été élaborée avec une belle dose de houblons tout en faisant \
                        la part belle à une base maltée bien travaillée. Les houblons NECTARON et NELSON SAUVIN \
                        apportent respectivement des arômes de fruits tropicaux et de raisons blancs  pour délivrer\
                         une amertume agréable et rafraichissante. Elle est légère en alcool (à peine 5% vol. alc) pour encore plus de plaisir!" 
        },
        {
            id : "emmenez-moi",
            nom : "Emmenez-moi !",
            style : "MÄRZEN AMBRÉE",
            photo1 : MOCK_EMMENEZ,
            photo2 : MOCK_EMMENEZ,
            EBC : 31,
            IBU : 24,
            houblons : ["Hallertau", "Mittelfrüh", "Mistral"],
            malts : ["Pilsen", "Carapils", "Vienna", "malts Cara"],
            levure : ["W34-70"],
            alcool : 5.2,
            amertume : "Amertume modérée",
            desc_courte : "Une bière ambrée de fermentation basse",
            desc_longue :"Notre Märzen, bière à la couleur ambrée, offre un profil de saveurs riches et complexes. \
                        Elle présente des notes de caramel et de pain grillé apportées par les malts torréfiés, \
                        et elle bénéficie d'une amertume douce à la fois herbacée ( Hallertau) et florale ( Mistral)" 
        },
        // {
        //     id : "etvoila",
        //     nom : "Et voilà! (Éphémère épuisée)",
        //     style : "Session IPA",
        //     photo1 : MOCK_EV,
        //     photo2 : ET_verre,
        //     EBC : 7,
        //     IBU : 23,
        //     houblons : ["Azacca", "Hallertau blanc"],
        //     malts : ["Pale Ale", "Pilsen", "Vienna"],
        //     levure : ["Verdant IPA"],
        //     alcool : 4,
        //     amertume : "Amertume",
        //     desc_courte : "Une SESSION IPA légère et désaltérante, aux accents de fruits tropicaux.",
        //     desc_longue :"Cette IPA de couleur claire et à la robe légèrement trouble est aromatisée de deux houblons que \
        //                 l’on a souhaité associer pour amener des arômes et de la fraîcheur tout en travaillant une belle \
        //                 amertume équilibrée. L’ Azacca ( houblon Américain) apporte des arômes de fruits tropicaux, d’agrumes \
        //                 et de pin, tandis que l’ Hallertau ( houblon Allemand) apporte des notes rafraîchissantes de fleurs, \
        //                 de fruits jaunes et rouges, et de sauvignon blanc. Un mélange des 2 mondes comme on aime avec une belle base maltée!" 
        // },
        {
            id : "fichtre",
            nom : "Fichtre!",
            style : "COLD IPA",
            photo1 : MOCK_F,
            photo2 : MOCK_F,
            EBC : 6.5,
            IBU : 39,
            houblons : ["Azacca", "Citra", "Centennial"],
            malts : ["Pilsen", "flocons de maïs", "flocons de riz"],
            levure : ["S.04"],
            alcool : 5.5,
            amertume : "Amertume modérée",
            desc_courte : "Une délicieuse COLD IPA brassée par nos soins.",
            desc_longue :"Cette COLD IPA se déguste en toute circonstances et particulièrement à l'apéritif accompagnée de tapas!" 
        },
        {
            id : "disdonc",
            nom : "Dis donc!",
            style : "HOPPY PALE ALE",
            photo1 : MOCK_DD,
            photo2 : MOCK_DD,
            EBC : 10,
            IBU : 30,
            houblons : ["Citra", "Mandarina Bavaria"],
            malts : ["Pale Ale", "Froment"],
            levure : ["S.04"],
            alcool : 5.5,
            amertume : "Amertume modérée",
            desc_courte : "Une HOPPY PALE ALE savoureuse brassée par nos soins.",
            desc_longue :"Avec sa légère amertume rafraichissante, elle est idéale pour l'apéritif et la fête! La Street Food lui convient parfaitement." 
        },
        {
            id : "love",
            nom : "Love etc...",
            style : "PALE ALE",
            photo1 : MOCK_LOVE,
            photo2 : MOCK_LOVE,
            EBC : 20,
            IBU : 21,
            houblons : ["Hallertau blanc"],
            malts : ["Pale Ale", "Froment"],
            levure : ["BRY 97"],
            alcool : 5,
            amertume : "Amertume très modérée",
            desc_courte : "Une PALE ALE pleine d'amour à partager à deux.",
            desc_longue :"Sur une base de Pale Ale, cette recette est travaillée avec de la purée de mangue et de la purée de passion.\
                        Fruitée, légère , elle est à partager à deux, pour la St Valentin et en toutes autres circonstances ,du moment\
                         que vous ne \"manguez pas de passion et d'amour !\"" 
        }
    ]


    var biere_affichee = biere_list.find(item => item.id === beerId);

    if (!biere_affichee) {
        
        biere_affichee = biere_list[0];
    }

    // console.log(biere_affichee);

    sendGA4Event("Page detail biere", "Visite de page", biere_affichee.id);


    return (
        <div className="page-content-frame">
            <div className="bierePDpage-frame">
                <GoBackButton/>
                <div className="bierePDpage-blocHaut">
                    <div className="bierePDpage-imgcontainer" id="imgtop" >
                        <img className="bierePDpage-image" src={biere_affichee.photo1} alt={"photo du devant la bière " + biere_affichee.nom} />
                    </div>
                    
                    <div className="bierePDpage-blocHaut-container">
                        <h1 className="bierePDpage-blocHaut-titre">{biere_affichee.nom}</h1>
                        <h3 className="bierePDpage-blocHaut-style">{biere_affichee.style}</h3>
                        <span className="bierePDpage-blocHaut-shortdesc">{biere_affichee.desc_courte}</span>
                        <a className="bierePDpage-blocHaut-bouton" href="https://shop.brasseriesansfacon.fr" target="_blank">Commander en ligne</a> 
                    </div>

                    
                </div>

                <div className="bierePDpage-blocHaut">
                    
                    
                    
                    <div className="bierePDpage-blocHaut-container">
                        <h2 className="bierePDpage-blocHaut-carac">Caractéristiques</h2>
                        <div className="bierePDpage-blocHaut-ingredients">
                            <span className="bierePDpage-blocHaut-ingredient-ligne"><span style={{fontWeight : "bold", color : "inherit", marginRight : "5px"}}>HOUBLONS  </span>
                                {
                                    biere_affichee.houblons.map(item => (
                                        " " +item + ","
                                    ))
                                }
                            </span>
                            <br/>
                            <span className="bierePDpage-blocHaut-ingredient-ligne"><span style={{fontWeight : "bold", color : "inherit", marginRight : "5px"}}>MALTS  </span>
                                {
                                    biere_affichee.malts.map(item => (
                                        " " +item + ","
                                    ))
                                }
                            </span>
                            <br/>


                            <span className="bierePDpage-blocHaut-ingredient-ligne"><span style={{fontWeight : "bold", color : "inherit", marginRight : "5px"}}>LEVURE  </span>
                                {
                                    biere_affichee.levure.map(item => (
                                        " " +item + ","
                                    ))
                                }
                            </span>
                            <br/>



                        </div>
                        
                        <div className="bierePDpage-blocHaut-widgetcontainer">
                            <EbcWidget ebcvalue={biere_affichee.EBC}/>
                            <br/>
                            <IbuWidget ibuvalue={biere_affichee.IBU} amertume={biere_affichee.amertume} />
                        </div>
                        <span className="bierePDpage-blocHaut-soustexte">
                            { biere_affichee.alcool}% vol.alc
                            <span style={{fontStyle : "italic", color : "inherit"}}> Peut contenir des traces de gluten</span>
                        </span>

                         
                    </div>
                    <div className="bierePDpage-imgcontainer" id="imgbottom" >
                        <img className="bierePDpage-image" src={biere_affichee.photo2} alt={"photo d'un verre de bière " + biere_affichee.nom} />
                    </div>
                    
                </div>
                <span className="bierePDpage-longdesc">{biere_affichee.desc_longue}</span>
                
            </div>

        </div>
    )
}

export default BieresPermaDetailsPage;