import BieresListWidget from "../components/BieresListWidget";
import GoBackButton from "../components/GoBackButton";
import "../styles/BieresListPage.css";


import MOCK_P from "../assets/bieres/mockup_P.png";
import MOCK_EV from "../assets/bieres/mockup_EV_outstock.png";
import MOCK_OLL from "../assets/bieres/mockup_OLL.png";
import MOCK_BV from "../assets/bieres/mockup_BV.png";
import MOCK_F from "../assets/bieres/mockup_F.png";
import MOCK_DD from "../assets/bieres/mockup_DD.png";
import MOCK_SMASH from "../assets/bieres/mockup_smash_outstock.png";
import MOCK_ALORS from "../assets/bieres/mockup_alors_outstock.png";
import MOCK_SAPER from "../assets/bieres/mockup_saper.png";
import MOCK_SWEET from "../assets/bieres/mockup_sweet.png";
import MOCK_EMMENEZ from "../assets/bieres/mockup_emmenez.png";
import MOCK_LOVE from "../assets/bieres/mockup_love.png";


import sendGA4Event from "../components/GA4Function";

function BieresListPage() {
    const bieres = [
    
    {
        nom: "Saperlipopette !",
        image : MOCK_SAPER,
        style : "WHEAT ALE",
        id: "saperlipopette"
    },
    {
        nom: "Emmenez-moi !",
        image : MOCK_EMMENEZ,
        style : "MÄRZEN AMBRÉE",
        id: "emmenez-moi"
    },
    {
        nom: "Love etc...",
        image : MOCK_LOVE,
        style : "PALE ALE",
        id: "love"
    },
    {
        nom: "Sweet As !",
        image : MOCK_SWEET,
        style : "New Zealand IPA",
        id: "sweetas"
    },
    // {
    //     nom: "(Éphémère épuisée) Et voilà!",
    //     image : MOCK_EV,
    //     style : "SESSION IPA",
    //     id: "etvoila"
    // },
    {
        nom: "Dis donc!",
        image : MOCK_DD,
        style : "HOPPY PALE ALE",
        id:"disdonc"
    },
    {
        nom: "Papounet",
        image : MOCK_P,
        style : "SAISON au poivre",
        id:"papounet"
    },
    {
        nom: "Fichtre!",
        image : MOCK_F,
        style : "COLD IPA",
        id:"fichtre"
    },
    {
        nom: "Oh là là!",
        image : MOCK_OLL,
        style : "NEIPA",
        id: "ohlala"
    },
    {
        nom: "Ça alors!",
        image : MOCK_ALORS,
        style : "HOPPY LAGER",
        id: "caalors"
    },
    {
        nom: "SMASH... et Bim !",
        image : MOCK_SMASH,
        style : "S.M.A.S.H",
        id: "smash"
    }
    // ,{
    //     nom: "(Éphémère épuisée) Bon V🛫!",
    //     image : MOCK_BV,
    //     style : "IPA Ambrée",
    //     id:"bonv"
    // }
    ];

    sendGA4Event("page biere list", "Visite de page");

    return (
        <div className="page-content-frame">
            <div className="biereslistpage-frame">
                <GoBackButton/>
                <span className="biereslistpage-titre"><h2>Nos bières :</h2></span>
                <div className="biereslistpage-container">
                    {/* <BieresListWidget biere={biere}/>
                    <BieresListWidget biere={biere} />
                    <BieresListWidget biere={biere}/> */}
                    {
                        bieres.map(item => (
                            <BieresListWidget biere={item}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default BieresListPage;